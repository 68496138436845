export default {
    "title": "标题",
    "home": "首页",
    "Reviews": "点评",
    "Brokers": "交易商",
    "FinTech": "金融科技",
    "LP": "流动性提供商",
    "Pay": "支付公司",
    "TopBrokers": "排行榜",
    "Promotion": "推广活动",
    "Vlog": "视频资讯",
    "Blog": "博客",
    "Creativecenter": "创作中心",
    "Top List": "排行榜",
    "Top Trader": "优秀交易员",
    "Verify Account": "实盘认证",
    "Notice": "消息通知",
    "System Notifications": "系统通知",
    

    "language": "语言",
    "Sign in" : "登录",
    "Sign up" : "注册",
    "Log Out" : "退出",
    "Modify Password" : "修改密码",
    "Request timeout": "网络请求超时",
    "Network error": "网络错误",
    "videoerror": "此视频暂无法播放，请稍后再试",
    index: {
      "banner": "诚信的点评，公正的排名",
      "searchcon": "请输入公司名称",
      "search": "搜索",
      "cont1": "热门品牌",
      "cont2": "优质的平台，助您把握市场机遇",
      "cont3": "近期点评",
    },
    footer:{
      "work": "加入我们",
      "work1": "Tradervote是一个对所有人开放的点评平台，分享你的经验，帮助他人做出更好的选择。",
      "login": "登录",
      "cont1": "订阅通知",
      "cont2": "请输入您的邮箱",
      "cont3": "订阅",
      "cont4": "现在注册，写下您的点评。",
      "cont5" : "公司信息",
      "cont6" : "关于我们",
      "cont7" : "帮助交流",
      "cont8" : "帮助中心",
      "cont9" : "下载app",
      "cont10" : "关注我们",
      "cont11" : "隐私政策",
      "cont12" : "用户服务协议",
      "cont13" : "Cookie agreement",
    },
    
    login: {
      'cont1': '请阅读并同意协议',
      'cont2': '请输入邮箱或用户名。',
      'cont3': '请输入密码。',
      'cont4': '密码长度为8-12位数字。',
      'cont5': '请输入用户名。',
      'cont6': '禁用特殊字符，长度为3至15。',
      'cont7': '请输入验证码。',
      'cont8': '请输入邮箱。',
      'cont9': '邮箱格式不正确。',
      "cont10" : "邮箱",
      "cont11": "用户名",
      "cont12": "密码",
      "cont13": "验证码",
      "cont14": "我同意",
      "cont15" : "条款",
      "cont16" : "和",
      "cont17" : "隐私政策",
      "cont18" : "已有账户？",
      "cont19" : "第三方登录",
      "cont20" : "发送",
      "cont21": "秒后重新发送",
      "cont22": "正在发送",
      "cont23": "个人",
      "cont24": "公司",
      "cont25" : "新朋友！",
      "cont26" : "欢迎回来！",
      "cont27" : "电子邮件或用户名",
      "cont28" : "记住密码",
      "cont29" : "没有账户？",
      "cont30" : "找回密码？",
      "cont31": "找回密码",
      "cont32": "新密码",
      "cont33": "确认密码",
      "cont34": "上传头像图片只能为JPEG/JPG/PNG格式！",
      "cont35" : "上传的头像图片大小不能超过2MB！",
      "cont36" : "请再次输入密码。",
      "cont37" : "两个输入不匹配！",
      "cont38" : "链接已复制",
      "cont39" : "请输入旧密码。",
      "cont40" : "长度必须大于8位数字。",
      "cont41": "长度必须少于 12 位数字。",
      "cont42": "请输入新密码。",
      "cont43": "请输入确认密码。",
      "cont44": "请先绑定邮箱",
      "cont45" : "请输入内容",
      "cont46" : "FaceBook 登录",
      "cont47" : "用户",
      "cont48" : "",
      "cont49" : "",
      "cont50" : "",
    },
    user:{
      "cont1": "个人设置",
      "cont2": "上传新的头像",
      "cont3": "绑定邮箱",
      "cont4": "您的头像：",
      "cont5" : "邮箱：",
      "cont6" : "确认绑定",
      "cont7" : "用户名(公开可见)：",
      "cont8" : "国家：",
      "cont9" : "个人资料：",
      "cont10" : "强制的",
      "cont11": "请输入用户名",
      "cont12": "请选择国家",
      "cont13": "请输入用户名",
      "cont14": "长度应为 3 到 12",
      "cont15" : "请输入邮箱或用户名。",
      "cont16" : "邮箱格式不正确。",
      "cont17" : "修改密码",
      "cont18" : "旧密码",
      "cont19" : "新密码",
      "cont20" : "确认",
      "cont21": "邮箱",
      "cont22": "去绑定邮箱",
      "cont23": "",
      "cont24": "",
      "cont25" : "",
      "cont26" : "",
      "cont27" : "",
      "cont28" : "",
      "cont29" : "",
      "cont30" : "",
      "cont31": "",
      "cont32": "",
      "cont33": "",
      "cont34": "",
      "cont35" : "",
      "cont36" : "",
      "cont37" : "",
      "cont38" : "",
      "cont39" : "",
      "cont40" : "",
    },
    detail: {
      "about": "关于",
      "service": "产品服务",
      "assets": "交易商种类",
      "platforms": "交易平台",
      "leverage": "最大杠杆",
      "licenses": "国家监管",
      "method": "付款方式",
      "contact": "关于我们",
      "support": "支持币种",
      "cont1": "访问此网站",
      "cont2": "进行评分",
      "cont3": "评分和评论",
      "cont4": "条",
      "cont5" : "星",
      "cont6" : "重置",
      "cont7" : "长度应为 15 到 1000",
      "cont8" : "评分最高",
      "cont9" : "您的审核尚待完成。",
      "cont10" : "评论",
      "cont11": "审核日期",
      "cont12": "回顾",
      "cont13": "编辑",
      "cont14": "删除",
      "cont15" : "诚实评论，公平排名",
      "cont16" : "了解更多",
      "cont17" : "",
      "cont18" : "",
      "cont19" : "",
      "cont20" : "",
    },
    about:{
      "cont1": "关于Tradervote",
      "cont2": "Tradervote 是一家致力于改善外汇交易市场环境的专业点评公司。我们的团队由经验丰富的外汇交易者、分析师和技术专家组成，旨在通过真实、客观的评论来监管和约束交易商的行为，为投资者提供更安全、更有效的服务。",
      "cont3": "公司使命和目标",
      "cont4": "使命：我们的使命是改善外汇交易市场的透明度和可靠性。",
      "cont5" : "来自真实客户的反馈",
      "cont6" : "Tradervote网站，就像一盏明灯，为我们照亮了前行的道路。这个网站汇聚了金融领域的权威专家，他们用专业的知识和丰富的经验，为我们提供最真实、最客观的金融产品点评。",
      "cont7" : "Tradervote功能非常强大。在这里，我可以与其他投资者交流心得，分享经验，共同成长。大家相互学习，相互鼓励，共同追求财富的梦想。",
      "cont8" : "我对这个网站的服务非常满意，Tradervote的提供了客观真实的点评。通过它，我能更好地评估投资风险，做出更理性的决策。",
      "cont9" : "我觉得Tradervote是一个非常实用的金融工具，它的行业排行榜让我找到了一些适合自己的投资机会。推荐给大家！",
      "cont10" : "我一直在寻找一个可靠的金融点评网站，Tradervote完全满足了我的需求。",
      "cont11": "目标：",
      "cont12": "提供真实、公正的交易商点评，帮助投资者做出明智的决策。",
      "cont13": "监督交易商的行为，防止恶意虚假评论和敲诈勒索。",
      "cont14": "我们将继续努力，成为投资者信赖的外汇交易信息平台，促进市场的健康发展。",
      "cont15" : "",
      "cont16" : "服务范围：",
      "cont17" : "交易商点评： 我们对各大外汇交易商进行客观评价，包括交易条件、资金安全、客户支持等方面。",
      "cont18" : "真实评论： 我们鼓励投资者分享真实的交易体验，以帮助其他人做出明智的选择。",
      "cont19" : "投资者教育： 我们提供有关外汇交易的教育资源，帮助投资者提高交易技能。",
      "cont20" : "未来展望：",
      
    },
    topchat:{
      "cont1": "一款安全、智能的端到端加密即时通讯软件。",
      "cont2": "与朋友、好有和同事畅聊",
      "cont3": "Android下载TraderChat App",
      "cont4": "有效帮助您完成每日工作的人工智能助手",
      "cont5" : "信息",
      "cont6" : "方便快捷进行单聊或群聊沟通。",
      "cont7" : "通话",
      "cont8" : "单聊或群聊都支持语音通话和视频通话。",
      "cont9" : "ChatGPT 4.0",
      "cont10" : "帮您完成搜索，撰写电子邮件，视频脚本，翻译等工作。",
      "cont11": "用户反馈",
      "cont12": "“我一直在寻找一款安全方便的即时通讯应用，TraderChat完全符合我的期望!”我喜欢它干净直观的界面，语音和视频通话质量非常好。终于，我可以随时随地与家人和朋友保持联系了!”",
      "cont13": "“作为一名商务人士，我经常需要与客户和同事进行即时沟通。TraderChat提供了一个安全的通信环境和智能助手功能，帮助我更有效地管理工作任务。多亏了这个出色的应用程序!”",
      "cont14": "“我非常重视隐私保护，所以我选择了TraderChat作为我的主要沟通工具。它的加密技术保证了我个人信息的安全，让我可以自信地与朋友分享生活中的时刻。强烈推荐给重视隐私的用户!”",
      "cont15" : "“TraderChat的多平台支持非常棒!我可以在我的手机、平板电脑和电脑上使用它，消息同步又快又稳定。现在我可以随时随地与我的家人和朋友保持联系。太方便了!”",
      "cont16" : "你准备好立即下载应用程序了吗？",
      "cont17" : "TraderChat Web",
      "cont18" : "",
      "cont19" : "",
      "cont20" : "",
    },
    vlog:{
      "cont1": "+关注",
      "cont2": "已关注",
      "cont3": "发布于",
      "cont4": "点赞",
      "cont5" : "评论",
      "cont6" : "收藏",
      "cont7" : "转发",
      "cont8" : "分享",
      "cont9" : "个人简介",
      "cont10" : "粉丝",
      "cont11": "获得",
      "cont12": "次赞",
      "cont13": "收藏",
      "cont14": "关于Tradervote",
      "cont15" : "常见问题",
      "cont16" : "联系我们",
      "cont17" : "创作中心",
      "cont18" : "发布创作",
      "cont19" : "关注",
      "cont20" : "创作",
      "cont21": "封面：",
      "cont22": "视频",
      "cont23": "图文",
      "cont24": "标题",
      "cont25" : "描述",
      "cont26" : "类型",
      "cont27" : "点击上传",
      "cont28" : "小于10M的视频/mp4文件。",
      "cont29" : "请输入标题",
      "cont30" : "请输入描述",
      "cont31": "请上传封面",
      "cont32": "请上传图文",
      "cont33": "时间",
      "cont34": "赞",
      "cont35" : "帖子",
      "cont36" : "文章",
      "cont37" : "类别",
      "cont38" : "最近更新",
      "cont39" : "分享",
      "cont40" : "",
    },
    service:{
      "cont1": "Before using Tradervote services, you should carefully read the content of this Tradervote User Service Agreement, confirm that this Tradervote User Service Agreement has been known, understood and accepted, and have agreed to use it as the basis for determining the rights and obligations of both parties.",
      "cont2": 'The parties to these Terms are TOP NEW FUTURE TECHNOLOGY COMPANY LIMITED (" Tradervote "or" We ") and the user of the services related to Tradervote (" User "or" You "), These Terms are the terms of Service between you and Tradervote with respect to your use of the services provided by Tradervote and have the effect of a formal written contract.',
      "cont3": "You understand and agree that we will revise and update these Terms or the Service Rules from time to time as agreed in the Tradervote User Service Agreement. The release and implementation of the updated content are subject to the relevant provisions of the Tradervote User Service Agreement.",
      "cont4": "1. User account",
      "cont5" : "1.1 When registering, managing and using the account, you should follow the principle of good faith, legality and good faith. The relevant registration materials you submit to the platform shall comply with laws and regulations, the legitimate rights and interests of citizens, public order and good customs, and the principles of information authenticity, and shall not submit any illegal or undesirable information. If the relevant information changes, you should update it in time. If the registration information provided by you is illegal, untrue, inaccurate or not updated in time, resulting in relevant legal responsibilities or adverse consequences, you will bear the corresponding legal responsibilities and adverse consequences. At the same time, Tradervote reserves the right to refuse to provide you with registration services.",
      "cont6" : "1.1 When registering, managing and using the account, you should follow the principle of good faith, legality and good faith. The relevant registration materials you submit to the platform shall comply with laws and regulations, the legitimate rights and interests of citizens, public order and good customs, and the principles of information authenticity, and shall not submit any illegal or undesirable information. If the relevant information changes, you should update it in time. If the registration information provided by you is illegal, untrue, inaccurate or not updated in time, resulting in relevant legal responsibilities or adverse consequences, you will bear the corresponding legal responsibilities and adverse consequences. At the same time, Tradervote reserves the right to refuse to provide you with registration services.",
      "cont7" : "1.2.1 Your account nickname, profile picture, personal introduction and other personal information shall not appear illegal and bad information, including but not limited to the use of political, pornographic, vulgar, insulting, libel and other illegal and moral words.",
      "cont8" : "1.2.2 You may not use another person's name (including but not limited to using another person's name, name, font, avatar, identity, etc., or in any other manner that may cause confusion) to open an Tradervote review account without their permission.",
      "cont9" : "1.2.3 It is not allowed to create an Tradervote account by impersonating, copying, or fabricating the names and identification information of organizations such as news media or in any other manner that may cause confusion.",
      "cont10" : "1.2.4 You shall not maliciously register Tradervote accounts (including but not limited to frequent registration, batch registration and other behaviors) or use Tradervote accounts for illegal or improper purposes (including but not limited to traffic fraud and other behaviors), shall not carry out any acts that harm the legitimate rights and interests of other citizens, and shall not take various technical means to maliciously circumvent or oppose the rules of the platform.",
      "cont11": "1.3 You understand and agree that the ownership and related rights and interests of your Tradervote account are all owned by Tradervote, and you only have the right to use the account and only your own use. In order to ensure the security of your account, you shall not grant, transfer, sell, lend or otherwise license the Tradervote account to others, otherwise you shall bear all the responsibilities arising therefrom. Tradervote reserves the right to refuse to provide the relevant services, freeze or withdraw the registered account or terminate this Service Agreement. You may also be required to indemnify Tradervote for losses sustained by you.",
      "cont12": "1.4 You shall properly keep account information, account password and other information and materials related to the account. You are responsible for maintaining the security and confidentiality of your personal account and password, and assume all legal responsibilities for the activities you engage in in the name of your registered account, including but not limited to all legal liabilities that may be caused by any information Posting, browsing and clicking you perform on the Tradervote platform. You should immediately notify Tradervote of any unauthorized use of your account or any other security breach.",
      "cont13": "1.5 You understand and agree that if you violate the above terms, Tradervote has the right to take measures against you such as requiring correction within a time limit, prohibiting registration, deleting or blocking illegal information, prohibiting the release of information within different time limits and modifying, blocking and cancelling account information.",
      "cont14": "1.6 You understand and agree that you may use the services and functions of Tradervote by registering a new account or logging in to your existing account, and your use of Tradervote account shall comply with the provisions of the Tradervote User Service Agreement.",
      "cont15" : "2. User management",
      "cont16" : "2.1 You acknowledge, understand and agree that the collection, storage, use, sharing and protection of users' personal information may be involved in the course of Tradervote's services. When you use the services provided by Tradervote, you agree that Tradervote collects, uses and shares personal information in accordance with the Tradervote Privacy Policy.",
      "cont17" : "2.2 Users have the right to post objective, real and personally experienced text reviews, picture reviews, video reviews and other information on the Tradervote platform. Users have the right to participate in the Tradervote community and post articles and opinions that comply with national laws and comply with the rules of the Tradervote community.",
      "cont18" : "2.3 You may receive and view events or information through official page announcements and/or in-site letters and/or emails and/or customer service calls and/or mobile phone messages, regular correspondence.",
      "cont19" : "2.4 Users have the right to receive rewards (such as contribution value, points, badges, etc.) from Dianping after Posting review information and other contributions according to relevant regulations of Tradervote.",
      "cont20" : "2.5 When users post information, you should ensure the authenticity, objectivity and legality of such information, Tradervote encourages users to contribute high-quality experience reviews. Users should ensure that any review information, guides or articles, pictures and videos posted on Tradervote do not infringe on the privacy, copyright or other legitimate rights of third parties. The user must maintain the objectivity of the review and shall not use the Tradervote user identity to engage in any behavior that violates laws, regulations, national policies and principles of good faith, including but not limited to:",
      "cont30" : "2.5.1 Violations of laws, regulations and national policies:",
      "cont31": "· Opposed to the fundamental principles set forth in the Constitution;",
      "cont32": "· Endangering national security, disclosing state secrets or subverting state power;",
      "cont33": "· Harm national honor and interests;",
      "cont34": "· Advocating terrorism or extremism or inciting the implementation of terrorist or extremist activities;",
      "cont35" : "· Spreading rumors to disrupt economic and social order;",
      "cont36" : "· Disseminating obscenity, pornography, gambling, violence, murder, terror or instigation of crime;",
      "cont37" : "· Insulting or defaming others, infringing upon the reputation, privacy and other legitimate rights and interests of others;",
      "cont38" : "· Other contents prohibited by laws and administrative regulations.",
      "cont39" : "2.5.2 Violation of the principle of good faith:",
      "cont40" : "· Hype and charge fees or gain benefits from merchants;",
      "cont41": "· Participate in or organize the writing and Posting of false reviews for the purpose of obtaining a profit or advantage;",
      "cont42": "· Threatening bad reviews to demand additional benefits or benefits from the merchant;",
      "cont43": "· Malicious slander of Tradervote or the goodwill of merchants by fabricating facts, insulting, defaming, etc.;",
      "cont44": "· Conduct other violations that affect the objectivity of reviews and disrupt the normal order of Tradervote.",
      "cont45" : "If you have committed any of the above actions, Tradervote reserves the right to take the following actions:",
      "cont46" : "① The violation information will be screened, and the warning will be issued according to the seriousness of the case;",
      "cont47" : "② Remove all reviews and pictures, cancel review stars and community ratings, and block tribal posts or tribes that involve commercial speculation.",
      "cont48" : "③ Temporarily/permanently restrict your use of your account to post information on the Tradervote platform;",
      "cont49" : "④ Other methods prescribed by laws, administrative regulations, these Terms and the Tradervote Platform User Service Agreement.",
      "cont50" : "2.6 Users are prohibited from using Tradervote in any form as a venue, platform or medium for any illegal activity. Without the authorization or permission of Tradervote, users may not use Tradervote's name for any commercial activities, and may not use Tradervote as a venue, platform or medium for commercial activities in any form.",
      "cont51": '2.7 Tradervote requires all search engines to follow the industry norms, namely "reject Robots access standard" (RobotsExclusionStandard), otherwise it will be regarded as your crawling behavior is a violation of our Tradervote property rights and intellectual property rights, the right to protect the interests of Tradervote through legal proceedings.',
      "cont52": "2.8 If you violate these Terms, the Tradervote User Service Agreement or any service rules posted by Tradervote from time to time, Tradervote reserves the right to take all necessary measures to the extent permitted by law, including but not limited to deleting the content posted by the user, canceling the stars, honors and virtual wealth obtained by the user on Tradervote. If you suspend or terminate your use of Tradervote account and cause losses to Tradervote, you shall be liable for all damages, including but not limited to damages for property damage, damages for reputation, legal costs, attorney's fees, notary fees, transportation expenses and other reasonable expenses arising out of rights protection. Tradervote reserves the right to deal with your conduct in accordance with these Terms and the relevant provisions of the Tradervote User Service Agreement.",
      "cont53": "3. Termination of service",
      "cont54": "3.1 You agree that Tradervote reserves the right to modify or discontinue any of its free Services provided to you at any time without prior notice to you. In respect of your paid transactions with Tradervote, you agree that Tradervote has the right to modify, discontinue, and deal with subsequent matters in accordance with the principles of fairness, good faith, and equal value for money at prior notice.",
      "cont55" : "3.2 If your account meets the following conditions, Tradervote has the right to terminate your use of Tradervote. As a result, your account will no longer be able to log in to Tradervote, and the corresponding service will be terminated:",
      "cont56" : "3.2.1 There is no active business that has not expired;",
      "cont57" : "3.2.2 Termination of your account and services does not violate the mandatory provisions of relevant laws and regulations.",
      "cont58" : "3.3 If you have any objection to these Terms and any amendments from time to time, you have the right to stop using Tradervote's services, or notify Tradervote through customer service and other channels to stop providing services to you. After discontinuation of the service, unless otherwise expressly provided by laws and regulations, Tradervote has the right (but not the obligation) to retain your account to access Tradervote's relevant information and data, or to retain or forward any website messages or short messages within any account.",
      "cont59" : "3.4 You agree that after the termination of your contractual relationship with Tradervote, Tradervote may still claim rights against you in accordance with these Terms in respect of your illegal conduct or violation of these Terms and/or other Service rules during the use of Tradervote Services.",
      "cont60" : "4. Intellectual property and other rights",
      "cont61": "4.1 The operation system of Tradervote platform and related services is independently developed, operated and provided with technical support by Tradervote, and Tradervote has full rights to all data and information generated during the development and operation of Tradervote services. Tradervote owns the copyright and patent rights of the software used by Tradervote to provide various services, as well as the trademarks, business images, business logos and know-how used.",
      "cont62": "4.2 You understand and agree that the intellectual property rights and ownership of all comments, articles, videos, feedback and other information published by you on Tradervote and its derivatives shall be governed by the relevant provisions in the Tradervote Platform User Service Agreement.",
      "cont63": "5. Exoneration",
      "cont64": "5.1 Unless expressly agreed by Tradervote in writing, Tradervote does not guarantee the accuracy, completeness or reliability of any content published by merchants, including but not limited to Merchant information, review content, etc. obtained by users from Tradervote in any way (including but not limited to inclusion, via, connection or download). Users should review and judge the relevant information at their own discretion and assume their own responsibility and risk for any products, services, information or materials purchased or obtained by users as a result of the content information on Tradervote. If the user is damaged as a result, Tradervote shall be exempt from liability to the maximum extent permitted by law. All user reviews, merchant or product information posted by Tradervote users only represent the user's personal views and do not mean that Tradervote endorsements their views or confirms their descriptions. Tradervote is exempt from liability to the maximum extent permitted by law.",
      "cont65" : "5.2 If you upload or publish any information or content on the Tradervote platform, you should keep your own backup. Tradervote is not responsible for the failure to save, modify, delete or store information posted by users, and is not responsible for typographical errors, omissions, etc. on Tradervote that are not intentionally caused by Tradervote. Tradervote has the right but no obligation to improve or correct any omissions or errors in any part of Tradervote.",
      "cont66" : "5.3 You acknowledge that the rankings published by Tradervote on its platform are solely generated based on real user evaluations, visits, browsing time and other data without manual intervention. These lists are only for your selection of services to provide a reference, do not involve any merchant paid advertising, you should use your own judgment on the properties of the listed services, Tradervote will not make any form of guarantee or warranty for the services listed in this list.",
      "cont67" : "5.4 Tradervote does not assume legal responsibility for any activities or information that is not obtained through Tradervote's formal channels.",
      "cont68" : "5.5 Tradervote reserves the right, to the extent permitted by law, without notice to you, to remove any information posted by users that does not comply with these Terms and Service rules, the Tradervote Platform User Service Agreement, and legal requirements. Tradervote will not be liable for any inconvenience or loss caused to you by the deletion of such information.",
      "cont69" : "5.6  You agree that, to the extent permitted by law, Tradervote shall in no event be liable for direct, indirect, incidental, special, punitive or other damages or losses of any person or entity, including but not limited to:",
      "cont70" : "5.6.1 You understand and agree that Tradervote is not responsible for any delays or errors in the information displayed by Tradervote due to the special nature of the Internet;",
      "cont71": "5.6.2 In the course of your use of Tradervote Services, Tradervote shall not be liable for any insult, defamation, omission, obscenity, pornography or profanity caused to you by a third party;",
      "cont72": "5.6.3 Other force majeure and exclusions specified in the Tradervote User Service Agreement.",
      "cont73": "6. Dispute resolution",
      "cont74": "6.1 In the event of any dispute between you and Tradervote arising from the content or implementation of these Terms, both parties shall try their best to resolve it amicably through negotiation. If no agreement can be reached through negotiation, either party may file a lawsuit with a court of competent jurisdiction.",
      "cont75" : "6.2 All disputes, claims or other matters arising from or in connection with your use of Tradervote Services shall be governed by the laws of Vietnam, excluding the application of all conflict of laws provisions.",
      "cont76" : "7. Comments and feedback",
      "cont77" : "If the user has any requirements to explain the terms, please visit [Contact us] call instructions.",
    },
    privacy:{
      "cont1": 'TOP NEW FUTURE TECHNOLOGY COMPANY LIMITED(" We ") attaches great importance to the privacy of users (" You "). This policy applies to the products/services you currently use. When you use our products/services, we may collect and use information about you. Through this Privacy Policy, we want to explain to you how we collect, use, store and share this information when using our products/services, and the ways we provide you with access, update, control and protection of this information. This Privacy Policy is closely related to the products/services you use, we hope you read it carefully, and when necessary, make the appropriate choices according to the guidance of this Privacy Policy. For the relevant technical terms involved in this Privacy Policy, we try to express them in a concise and concise manner, and provide links to further instructions for your understanding. ',
      "cont2": "Special note: Before using the products/services provided by us, please carefully read and fully understand this Privacy Policy (the key contents of which we have made the font bold for your special attention) and make corresponding choices. By using or continuing to use our products/services, you consent to the processing of your information in accordance with this Privacy Policy. If the user has no civil capacity (minors under the age of 14), it needs to be accompanied by a guardian. Guardians are required to properly perform their guardianship duties and fully read the following agreement to ensure that you are fully aware of this policy before your child uses this product.",
      "cont3": "1. How do we collect and use your information",
      "cont4": "We will collect and use your personal information in accordance with the principles of legality, necessity and transparency for the purposes described in this policy. In this regard, we will separately explain to you the purpose, scope and use of the corresponding information by updating this policy, pop-up window, page prompts, etc., and provide you with the means of your own choice and consent, and collect and use after obtaining your express consent. If we use your personal information for other purposes not specified in this policy, or collect your personal information for other specific purposes, we will inform you in a reasonable manner and obtain your consent again before using.",
      "cont5" : "1.1 Information about the use of our products and services",
      "cont6" : "1.1.1 Read and write external stored information: When you are in use, we may need to store the data generated by you during the use of the product so that you can use it offline and save download traffic.",
      "cont7" : "1.1.2 Feedback, complaints and reporting information: When you give feedback or complain in the APP, we will collect the email information you proactively provide for the App platform customer service to contact you in time and accurately solve the problems you encounter in the process of using the App.",
      "cont8" : "1.2 How we collect your Information (1) When we need to use the above relevant permissions, we will apply to you for the necessary permissions, and after obtaining your authorization, the software will use the relevant permissions. (2) When the current application is running in the background, the application itself may still access your device status, external storage and other information for reading and loading product application data.",
      "cont9" : "1.3 How we may Use Information We may use the information we collect in the course of providing services to you for the following purposes: (1) To provide services to you; (2) for identity verification, customer service, security prevention, fraud detection, archiving and backup purposes when we provide services to ensure the security of the products and services we provide to you; (3) to help us design new services and improve our existing services; (4) To provide you with ads that are more relevant to you as an alternative to the ads that are commonly served.",
      "cont10" : "2. How do we store, share, transfer and publicly disclose your personal information",
      "cont11": "2.1 Store",
      "cont12": "2.1.1 Storage Location: We store your personal information collected and generated in the course of our operations in Vietnam in accordance with laws and regulations. At present, we do not transmit the above information abroad, and if we do, we will comply with the laws and regulations of Vietnam and foreign countries and seek your consent. ",
      "cont13": "2.1.2 Storage period: We only retain your personal information for the period necessary for the purpose of providing the current products and services. After the necessary period, we will delete or anonymize your personal information, except as otherwise provided by laws and regulations.",
      "cont14": "2.2 Share",
      "cont15" : "We do not share your personal information with any company, organization or individual, except in the following cases:",
      "cont16" : "2.2.1 Sharing with express consent: With your express consent, we will share your personal information with other parties.",
      "cont17" : "2.2.2 We may share your personal information in accordance with laws and regulations or mandatory requirements of competent government departments.",
      "cont18" : "2.2.3 Sharing with our Affiliates: Your personal information may be shared with our affiliates. We will only share necessary personal information, subject to the purposes stated in the User Privacy Policy. If the affiliated company wants to change the purpose of processing personal information, it will seek your authorized consent again. Our affiliates include any company or entity that is or will be controlled, controlled or under common control with us and the legal heirs of such company or entity. \"Control\" means having the ability, directly or indirectly, to influence the management of the company, whether through ownership, voting shares, contracts or other means recognized by the people's Court.",
      "cont19" : "2.3 Transfer",
      "cont20" : "We will not transfer your personal information to any company, organization or individual, except in the following circumstances：",
      "cont30" : "2.3.1 Transfer with express consent: After obtaining your express consent, we will transfer your personal information to other parties;",
      "cont31": "2.3.2 In the case of merger, acquisition or bankruptcy liquidation, such as the transfer of personal information, we will require the new company or organization that holds your personal information to continue to be bound by this user privacy policy, otherwise we will require the company or organization to seek authorization from you again.",
      "cont32": "2.4 Public disclosure",
      "cont33": "We will only publicly disclose your personal information in the following circumstances: ",
      "cont34": "2.4.1 With your express consent; ",
      "cont35" : "2.4.2 Disclosure based on law: We may publicly disclose your personal information in the case of laws, legal procedures, lawsuits or mandatory requirements of government authorities.",
      "cont36" : "3. How do we use cookies, Web beacons, and similar technologies",
      "cont37" : "The current application itself does not use such technology.",
      "cont38" : "4. Permissions and uses that will be requested from you during APP use",
      "cont39" : "The current application invokes some of your device permissions while providing services. Here are the instructions for the current application call permissions and what to ask you before invoking them. ",
      "cont40" : "You can choose to disable some or all of your permissions in the Settings function of the device to refuse the current application from collecting relevant personal information. The mode of displaying and disabling permissions may vary depending on the device. For details, see the device and system developer's instructions or guidelines. ",
      "cont41": "Please note that by enabling any permission, you authorize us to collect and use relevant personal information to provide corresponding services to you; If you close any rights, you cancel the authorization, we will no longer collect and use relevant personal information based on the corresponding rights, and will stop providing corresponding services to you. However, your action to disable permissions will not affect the previous collection and use of information based on your authorization.",
      "cont42": "4.1  have full network access rights for buffering and downloading audio, video, pictures and other resource files required by users to use the service.",
      "cont43": "4.2 check the network connection and its status to determine the network status, to prevent users from misusing a large number of carrier traffic, causing economic losses.",
      "cont44": "4.3 Connecting and disconnecting the WLAN network is used to buffer and download audio, video, pictures and other resource files required by users to use the service.",
      "cont45" : "4.4 Write the permission to the external memory card to save the Vietnamese fluent dynamic pictures downloaded by the user.",
      "cont46" : "4.5 Install application permissions This permission is required for application download and upgrade installation.",
      "cont47" : "5. How do you access and control your personal information",
      "cont48" : "The personal information collected and saved by the current application does not contain your personally identifiable information and has been anonymized and de-identified.",
      "cont49" : "6. How to withdraw authorization",
      "cont50" : "If you no longer wish to grant this app the permission to read and write relevant information, you can withdraw your authorization by selecting \"Settings - Applications - Permission Management\" on your mobile device. Please understand that each business function requires some basic information to be completed. After you withdraw your authorization, we will not be able to continue to provide you with the corresponding services and functions. However, your decision to withdraw your consent or authorization will not affect information previously read based on your authorization.",
      "cont51": "7. Minors use our services",
      "cont52": "We encourage parents or guardians to direct minors under the age of 18 to use our products/services. We recommend that minors encourage their parents or guardians to read this Privacy Policy and that minors seek consent and guidance from their parents or guardians before submitting personal information.",
      "cont53": "8. Complaint and report",
      "cont54": "You can make a complaint or report in accordance with our published system. If you believe that your personal information rights may be infringed, or if you find clues that your personal information rights are infringed (for example, you believe that the personal information we collect violates the law or the agreement between the parties), you can contact us by clicking on the user feedback interface. You can also send complaints to us directly by email to our customer service email address ",
      "cont55" : "9. Scope of application of the Privacy Policy",
      "cont56" : "This Privacy Policy applies to all of our products/services except for certain specific products/services. These specific products/services will be subject to specific privacy policies. Specific privacy policies for certain products/services will be more specific about how we use your information in those products/services. The privacy policy for that particular product/service forms part of this Privacy Policy. If the privacy policy of a particular product/service is inconsistent with this Privacy Policy, the privacy policy of that particular product/service shall apply.",
      "cont57" : "10. Revision of the Privacy Policy",
      "cont58" : "This Privacy Statement will be amended from time to time as necessary. We recommend that users regularly review our privacy policy and Terms of Use online. In the future, if there are any updates to the terms, we will not actively remind the user, will not retrospectively apply the situation that occurred in the past, and will not change the way we treat the information collected before.",
      "cont59" : " . We will check and respond to your complaint and report within 15 working days.",
    },
    cookie:{
      "cont1": "什么是“Cookies”？",
      "cont2": "Cookie是小型文本文件，在你同意（如需要）访问我们的网站或应用程序后，cookie会传输到用户计算机或移动设备。在以后的每一次访问中，网络浏览器（例如IE浏览器、Safari浏览器或谷歌浏览器）都会将这些cookie发送回网站或应用程序，以便识别你的个人详细信息或用户偏好。",
      "cont3": "Cookies非常有用，可以完成许多不同的任务。他们通过调整网站以满足你的需求，帮助改善网站体验。它们可以识别你的偏好并改善总体用户体验。",
      "cont4": "这里有两种Cookie",
      "cont5" : "会话Cookie。这些cookies仅在联机会话期间有效，在你关闭网络浏览器时将从计算机或设备中消失。",
      "cont6" : "长期Cookie。浏览器关闭后，这些cookie将保留在你的计算机或设备上，并在cookie中列出的时间段内持续。每次访问为其创建cookie的网站时，这些长期cookie都将被激活。",
      "cont7" : "我们怎样使用”Cookies“？",
      "cont8" : "我们在网站、移动网站和移动应用程序上使用cookies，以提高其可用性，并收集和保存数据，用于营销和优化目的。Cookie是我们网站上的小型文本文件，保存在你的硬盘上，随后被网络浏览器识别。我们的cookie不包含任何你的数据。相反，它们使用假名用户配置文件，不会对用户系统造成任何损坏。",
      "cont9" : "我们不会出售cookie收集的数据，也不会将这些信息转发给任何第三方，除非法律要求（例如，国家当局或法律代表）。",
      "cont10" : "浏览我们的网站，即表示你同意我们使用本cookie政策中说明的内容。此外，还可以通过单击cookie横幅上相应按钮或单击此页面底部的退出链接来选择退出。",
      "cont11": "此后，你可以随时通过更改浏览器的设置来撤消此权限。你可以在浏览器中设置，以便每次cookie试图访问计算机或移动设备时你都会被要求获得权限，或者在某些情况下禁用或完全禁用cookie的使用。然而，这样做可能会导致我们网站上的许多功能无法正常运行。",
      "cont12": "我们使用的是哪种类别的”cookies“？",
      "cont13": "使用我们的网站、移动网站或移动应用程序时，你的设备上可能会安装以下类别的cookie：",
      "cont14": "绝对必需性Cookies",
      "cont15" : "这些cookie非常重要，因为它可以帮助浏览我们的网站并使用我们的注册页面等功能。如果没有这些cookie，网站将无法正常运行。这些cookie不会收集任何关于你的数据。",
      "cont16" : "功能性Cookies",
      "cont17" : "这些cookie允许网站和应用程序记住你的偏好（如用户名或语言设置）。它们允许我们为你提供改进的个性化功能。我们无法使用这些cookie收集的信息来识别你的个人身份。",
      "cont18" : "性能化Cookies",
      "cont19" : "这些cookie通过提供有关访问区域、网站花费时间以及遇到的任何问题（如错误消息）的信息，帮助了解访问者如何与网站互动。这有助于我们提高网站的性能。",
      "cont20" : "分析/广告类Cookie",
      "cont30" : "为了确保网站、移动网站和移动应用程序便于使用和更新，我们使用网络分析服务Google Analytics来了解访问者如何使用我们的网站。例如，让我们能够看到网站上最受欢迎的内容，并确保内容不断更新和改进。我们收集的信息是匿名的，仅用于统计",
      "cont31": "目前，我们在网站上使用以下cookie：",
      "cont32": "Cookie名称",
      "cont33": "收集何种数据",
      "cont34": "追踪用户首次访问站点的时间。",
      "cont35" : "由基于PHP语言的应用程序生成的Cookie。",
      "cont36" : "无，不存储任何用户数据",
      "cont37" : "追踪访问者访问站点的次数。",
      "cont38" : "计算访问者在站点上停留的时间。",
      "cont39" : "追踪访客来源。",
      "cont40" : "追踪使用网站的访问者的数量。",
      "cont41": "Bazaar Voice在网站提供产品评论。",
      "cont42": "追踪使用Hotjar生成漏斗的示例中是否包含访问者。",
      "cont43": "New Relic-提供网站错误报告。",
      "cont44": "允许网站记住用户所做的选择，并在此情况下，选择cookie策略。",
      "cont45" : "New Relic-提供网站错误报告。",
      "cont46" : "New Relic-提供网站错误报告。",
      "cont47" : "追踪从Instagram广告中进入网站的用户及随后访问网址。",
      "cont48" : "Facebook Pixel设置Cookie。",
      "cont49" : "分类",
      "cont50" : "性能化Cookie",
      "cont51": "绝对必需性Cookie",
      "cont52": "分析类Cookie",
      "cont53": "广告类Cookies",
      "cont54": "使用期限",
      "cont55" : "2年",
      "cont56" : "4小时",
      "cont57" : "1年",
      "cont58" : "6个月",
      "cont59" : "24小时",
      "cont60" : "10个月",
      "cont61": "27年",
      "cont62": "390天",
      "cont63": "90天",
      "cont64": "",
      "cont65" : "",
      "cont66" : "",
      "cont67" : "",
      "cont68" : "",
      "cont69" : "",
      "cont70" : "",
    },
    trader:{
      "cont1": "推荐牛人",
      "cont2": "近一月收益率",
      "cont3": "总收益",
      "cont4": "交易时长",
      "cont5" : "天",
      "cont6" : "实盘竞技收益率排行榜",
      "cont7" : "交易时间",
      "cont8" : "收益率",
      "cont9" : "交易商",
      "cont10" : "全部",
      "cont11": "今日",
      "cont12": "近一周",
      "cont13": "近一月",
      "cont14": "近一年",
      "cont15" : "小于",
      "cont16" : "以上",
      "cont17" : "基本信息",
      "cont18" : "交易笔数",
      "cont19" : "交易手数",
      "cont20" : "近一周盈亏金额",
      "cont21": "总盈利率第",
      "cont22": "名",
      "cont23": "交易天数",
      "cont24": "类型",
      "cont25" : "手动",
      "cont26" : "混合",
      "cont27" : "信号描述",
      "cont28" : "盈利率",
      "cont29" : "总盈亏",
      "cont30" : "基本信息",
      "cont31": "用户名",
      "cont32": "开始时间",
      "cont33": "经纪商",
      "cont34": "服务器",
      "cont35" : "杠杆",
      "cont36" : "账户信息",
      "cont37" : "刷新",
      "cont38" : "当前余额",
      "cont39" : "当前净值",
      "cont40" : "入金",
      "cont41": "取款",
      "cont42": "手续费",
      "cont43": "总交易量(手数)",
      "cont44": "手",
      "cont45" : "交易笔数",
      "cont46" : "笔",
      "cont47" : "账户货币",
      "cont48" : "专注品种",
      "cont49" : "交易订单",
      "cont50" : "当前持仓",
      "cont51": "历史订单",
      "cont52": "交易类型",
      "cont53": "请选择",
      "cont54": "开仓时间",
      "cont55" : "交易品种",
      "cont56" : "手数",
      "cont57" : "开仓价格",
      "cont58" : "止损",
      "cont59" : "止盈",
      "cont60" : "利息",
      "cont61": "获利",
      "cont62": "收益曲线",
      "cont63": "盈亏分析",
      "cont64": "品种及单量占比",
      "cont65" : "单数",
      "cont66" : "利润",
      "cont67" : "盈亏总计",
      "cont68" : "总单数",
      "cont69" : "交易商账号管理",
      "cont70" : "绑定交易商",
      "cont71": "序号",
      "cont72": "账户号码（MT4）",
      "cont73": "策略昵称",
      "cont74": "状态",
      "cont75" : "公开",
      "cont76" : "隐藏",
      "cont77" : "操作",
      "cont78" : "修改",
      "cont79" : "删除账户后成绩将无法复原，您确定要删除吗？",
      "cont80" : "实盘认证",
      "cont81": "策略描述",
      "cont82": "没有找到想要的服务器？点击申请增加",
      "cont83": '"只读密码"仅用于将MT4账号与网站账户进行关联以使获得订单推送，FXTOP会严格保密，请放心使用',
      "cont84": "MT4账号",
      "cont85" : "只读密码",
      "cont86" : "账户状态",
      "cont87" : "公开账户",
      "cont88" : "隐藏账户",
      "cont89" : "保存信息",
      "cont90" : "请输入策略昵称",
      "cont91": "请输入策略描述",
      "cont92": "请选择类型",
      "cont93": "请选择经纪商",
      "cont94": "请选择服务器",
      "cont95" : "请输入MT4账号",
      "cont96" : "请输入只读密码",
      "cont97" : "收益金额",
      "cont98" : "做多",
      "cont99" : "做空",
      "cont100" : "美元",
      "cont101": "当日收益",
      "cont102": "已平仓收益/收益率",
      "cont103": "品种及单量占比",
      "cont104": "经纪商录入申请",
      "cont105" : "经纪商网址",
      "cont106" : "服务器名称",
      "cont107" : "示例MT4账号",
      "cont108" : "示例只读密码",
      "cont109" : "请输入经纪商",
      "cont110" : "请输入经纪商网址",
      "cont111": "请输入服务器",
      "cont112": "请输入MT4账号",
      "cont113": "请输入只读密码",
      "cont114": "状态",
      "cont115" : "",
      "cont116" : "",
      "cont117" : "",
      "cont118" : "",
      "cont119" : "",
      "cont120" : "",
    },
    comment:{
      "cont1": "评论",
      "cont2": "所有评分和评论",
      "cont3": "发表评论",
      "cont4": "您的评论",
      "cont5" : "提交评论",
      "cont6" : "请输入您的评论",
      "cont7" : "长度应为 15 到 1000",
      "cont8" : "评分最高",
      "cont9" : "我确认此评论是我自己的真实经历。我有资格留下此评论，并且没有获得任何奖励或报酬来为该公司留下评论。",
      "cont10" : "分数不能为0",
      "cont11": "请先勾选",
      "cont12": "回复",
      "cont13": "发布你的回复",
      "cont14": "",
      "cont15" : "",
      "cont16" : "",
      "cont17" : "",
      "cont18" : "",
      "cont19" : "",
      "cont20" : "",
    },
    broker:{
      "cont1": "最佳",
      "cont2": "比较此类别中的最佳公司",
      "cont3": "排序方式",
      "cont4": "数量",
      "cont5" : "评论数最多",
      "cont6" : "最近的评论",
      "cont7" : "总共",
      "cont8" : "条",
      "cont9" : "筛选",
      "cont10" : "评分",
      "cont11": "分类",
      "cont12": "推荐交易商",
      "cont13": "寻找最佳经纪商",
      "cont14": "寻找最佳交易员",
      "cont15" : "",
      "cont16" : "",
      "cont17" : "",
      "cont18" : "",
      "cont19" : "",
      "cont20" : "",
      "cont21": "",
      "cont22": "",
      "cont23": "",
      "cont24": "",
      "cont25" : "",
      "cont26" : "",
      "cont27" : "",
      "cont28" : "",
      "cont29" : "",
      "cont30" : "",
    },
    kong:{
      "cont1": "",
      "cont2": "",
      "cont3": "",
      "cont4": "",
      "cont5" : "",
      "cont6" : "",
      "cont7" : "",
      "cont8" : "",
      "cont9" : "",
      "cont10" : "",
      "cont11": "",
      "cont12": "",
      "cont13": "",
      "cont14": "",
      "cont15" : "",
      "cont16" : "",
      "cont17" : "",
      "cont18" : "",
      "cont19" : "",
      "cont20" : "",
      "cont21": "",
      "cont22": "",
      "cont23": "",
      "cont24": "",
      "cont25" : "",
      "cont26" : "",
      "cont27" : "",
      "cont28" : "",
      "cont29" : "",
      "cont30" : "",
      "cont31": "",
      "cont32": "",
      "cont33": "",
      "cont34": "",
      "cont35" : "",
      "cont36" : "",
      "cont37" : "",
      "cont38" : "",
      "cont39" : "",
      "cont40" : "",
    },
  };
  