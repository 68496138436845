<template>
    <div class="footer">
        <section class="section pt-5 pb-5 text-center bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h5 class="m-0">{{ $t('footer.work1') }}</h5>
                            <br/> 
                            <h5 class="m-0"><RouterLink to="/login">{{ $t('footer.work') }}</RouterLink>
                        </h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="footertop pt-5 pb-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-12 col-sm-12">
                        <h6 class="mb-3">{{ $t('footer.cont1') }}</h6>
                        <form class="newsletter-form mb-1">
                            <div class="input-group">
                                <input type="text" :placeholder="$t('footer.cont2')" class="form-control">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-primary">
                                        {{ $t('footer.cont3') }}
                                    </button>
                                </div>
                            </div>
                        </form>
                        <p>{{ $t('footer.cont4') }}</p>
                        <!-- <div class="app">
                            <p class="mb-2">DOWNLOAD APP</p>
                            <a href="#">
                                <img class="img-fluid" src="../../static/img/google.png">
                            </a>
                            <a href="#">
                                <img class="img-fluid" src="../../static/img/apple.png">
                            </a>
                        </div> -->
                    </div>
                    <div class="col-md-1 col-sm-6 mobile-none">
                    </div>
                    <div class="col-md-2 col-6 col-sm-4">
                        <h6 class="mb-3">{{ $t('footer.cont5') }}</h6>
                        <ul>
                            <li><router-link to="/about">{{ $t('footer.cont6') }}</router-link></li>
                            <li><router-link to="/topbrokers">{{ $t('TopBrokers') }}</router-link></li>
                            <li><router-link to="/promotion">{{ $t('Promotion') }}</router-link></li>
                            <li><router-link to="/vlog/blog">{{ $t('Vlog') }}</router-link></li>
                        </ul>
                    </div>
                    <div class="col-md-2 col-6 col-sm-4">
                        <h6 class="mb-3">{{ $t('footer.cont7') }}</h6>
                        <ul>
                            <li><a href="#">{{ $t('footer.cont8') }}</a></li>
                            <li><router-link to="/login">{{ $t('footer.login') }}</router-link></li>
                            <li><router-link to="/register">{{ $t('Sign up') }}</router-link></li>
                            <li><router-link to="/TraderChat">TraderChat</router-link></li>
                        </ul>
                    </div>
                    <div class="col-md-2 m-none col-4 col-sm-4">
                        <h6 class="mb-3">{{ $t('footer.cont10') }}</h6>
                        <ul>
                            <li><a href="https://x.com/tradervote" target="_blank"><img src="@/assets/img/twitter.png" style="width: 25px;"></a></li>
                            <li><a href="https://www.facebook.com/tradervote" target="_blank"><span class="iconfont icon-fmashangpin fontsize"></span></a></li>
                            <li><a href="https://www.instagram.com/tradervote/" target="_blank"><span class="iconfont icon-xiangji fontsize"></span></a></li>
                            <li><a href="https://www.youtube.com/@tradervote" target="_blank"><span class="iconfont icon-shipin fontsize"></span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="footer-bottom-search pt-5 pb-5 bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <p class="text-black">POPULAR COUNTRIES</p>
                        <div class="search-links">
                            <ul>
                                <li v-for="(item,index) in country">
                                    <template v-if="index!=0">|</template>
                                    <a href="javascript:;" @click="check(item.id)">{{ $i18n.locale=='zh'?item.name:item.Eglish }}</a> 
                                </li>
                            </ul>
                            <hr style="border-top: 1px solid rgb(255 255 255 / 30%);">
                            <ul>
                                <li><router-link to="/privacy">{{ $t('footer.cont11') }}</router-link></li>
                                <li>•</li>
                                <li><router-link to="/service">{{ $t('footer.cont12') }}</router-link></li>
                                <li>•</li>
                                <li><router-link to="/cookie">{{ $t('footer.cont13') }}</router-link></li>
                            </ul>
                        </div>
                </div>
            </div>
        </div>
    </section>
    <footer class="pt-4 pb-4 text-center">
        <div class="container">
            <p class="mt-0 mb-0">© Copyright 2022 Tradervote. All Rights Reserved TOP NEW FUTURE TECHNOLOGY COMPANY LIMITED</p>
        </div>
    </footer>
</div></template>

<script>
 import { country} from '@/network/listing.js'
export default {
    name: 'footerItem',
    data() {
      return {
         country: {}
      }
   },
   created() {
      country().then(res => {
         this.country = res.data
      })
   },
   methods:{
    check(id){
        const ids = encodeURIComponent(JSON.stringify(this.$encryptUtils.encrypt(id)));
        this.$router.push({path: "/search",query:{country:ids}})
    }
  }
}
</script>

<style scoped>
.search-links li{
    display: inline-block
}
.search-links li a:hover{
    color:rgba(255, 255, 255, 0.7);
}
.fontsize{
    font-size: 25px;
    color: #fff;
}
.footertop ul li{
    margin-bottom: 10px;
}
</style>
