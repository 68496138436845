<template>
    <div class="header">
        <nav class="navbar navbar-expand-lg navbar-light bg-light osahan-nav shadow-sm fixed-header">
            <div class="container">
                <a class="navbar-brand" href="/"><img alt="logo" src="@/assets/img/logo.png" style="width: 136px;"></a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item index">
                            <router-link to="/" class="nav-link">{{ $t('home') }}</router-link>
                        </li>

                        <li class="nav-item dropdown listing">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                {{ $t('Reviews') }}
                            </a>
                            <div class="dropdown-menu dropdown-menu-right shadow-sm border-0">
                                <router-link to="/listing/Brokers" class="nav-link">{{ $t('Brokers') }}</router-link>
                                <router-link to="/listing/FinTech" class="nav-link">{{ $t('FinTech') }}</router-link>
                                <router-link to="/listing/Lp" class="nav-link">{{ $t('LP') }}</router-link>
                                <router-link to="/listing/Pay" class="nav-link">{{ $t('Pay') }}</router-link>
                                <!-- <router-link to="/listing" class="nav-link">Crypto Exchange</router-link> -->
                            </div>
                        </li>
                        <li class="nav-item dropdown topbrokers">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                {{ $t('Top List') }}
                            </a>
                            <div class="dropdown-menu dropdown-menu-right shadow-sm border-0">
                                <router-link to="/topbrokers" class="nav-link">{{ $t('TopBrokers') }}</router-link>
                                <router-link to="/trader/index" class="nav-link">{{ $t('Top Trader') }}</router-link>
                            </div>
                        </li>
                        <li class="nav-item promotion">
                            <router-link to="/promotion" class="nav-link">{{ $t('Promotion') }}</router-link>
                        </li>
                        <li class="nav-item vlogindex">
                            <router-link to="/vlog/index" class="nav-link" style="float: left;">{{ $t('Vlog') }}</router-link>
                        </li>
                        <li class="nav-item vlogblog">
                            <router-link to="/vlog/blog" class="nav-link" style="float: left;">{{ $t('Blog') }}</router-link>
                        </li>
                        
                       <slot></slot>
                        
                        <li class="nav-item dropdown" id="customer_li">
                            <a class="nav-link dropdown-toggle" id="customer" href="#" role="button" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <el-icon size="20"><Service /></el-icon>
                                <!-- <span class="icon iconfont">&#xe645;</span> -->
                            </a>
                            <div class="dropdown-menu dropdown-menu-right shadow-sm border-0" id="customer_item">
                                <img src="@/assets/img/customer.png" class="img-fluid item-img">
                                <p style="padding: 7px 18px;margin-bottom: 0;">Telegram scan to add customer service</p>
                                <img src="@/assets/img/customer_1.png" class="img-fluid item-img">
                                <p style="padding: 7px 18px;margin-bottom: 0;">Telegram scan into the group</p>
                            </div>
                        </li>


                    </ul>

                </div>
            </div>
        </nav>
    </div>
</template>

<script>

export default {
    name: 'headerItem',
    data() {
       return{
        menu:''
       }
    },
    props: {

  },
  watch: {
    $route: {
      handler: function(to, from){
        this.path = to.path
        let path = to.path
        this.menu = path.substr(1)
        // console.log(this.menu)
        if(this.menu == '' || this.menu == 'home'){
            this.menu = 'index'
        }else if(this.menu.includes('listing') || this.menu.includes('search') || this.menu=='detail'){
            this.menu = 'listing'
        }else if(this.menu.includes('user')){
            this.menu = 'user'
        }else if(this.menu.includes('vlog/index') ){
            this.menu = 'vlogindex'
        }else if(this.menu.includes('vlog/blog') ){
            this.menu = 'vlogblog'
        }else if(this.menu.includes('trader')  || this.menu.includes('topbrokers')){
            this.menu = 'topbrokers'
        }else{
            this.menu = ''
        }
        // console.log(this.menu);
        if(this.menu !=""){
            $("#navbarNavDropdown .nav-item").removeClass('active');
            $("."+this.menu).addClass('active');
        }
      },
      deep: true
    }

  },
    mounted() {
        $(".navbar-nav .nav-item").mouseover(function () {
            $(this).addClass("show");
            $(this).children(".dropdown-menu").addClass("show");
        }).mouseout(function () {
            $(this).removeClass("show");
            $(this).children(".dropdown-menu").removeClass("show");
        });
        
    },
    methods: {
        
    }
}
</script>

<style scoped>
.item-img{
    padding: 7px 18px;width:100%;max-width:250px;
}
#customer_item{
    text-align: center;
}
@media (min-width: 768px){
    #customer_item{
    text-align: left;
    }
}
@media (min-width: 992px){
.navbar-expand-lg .navbar-nav .nav-link{
    padding-right: 1rem  !important;
    padding-left: 1rem !important;
}
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
</style>
